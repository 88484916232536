import React from 'react'
// Import SectionHeading
import SectionHeading from '../../Common/SectionHeading'
// Import ServiceCard
import ServiceCard from '../../Common/Service/ServiceCard'
// Import ServiceData
import {ServiceData} from '../../Common/Service/ServiceData'
//  OwlCarousel Slider Import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img10 from '../../../assets/img/service/serv-12.jpg'
import img11 from '../../../assets/img/service/Video.mov'

const ServiceHomeTwo = () => {

    let responsive = {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        960: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }
    return (
        <>
            <section id="home_two_service">
                <div className="container">
                    <SectionHeading heading="Taking care of you and your possessions all the way" para="Above the Line Transportation LLC is a point-to-point shipping company focusing on local and long distance, expedited freight, furniture pick ups and haul away. "/>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="service_slider_home_two">
                                <OwlCarousel className="owl-theme" responsive={responsive} autoplay={true}
                                             autoplayHoverPause={true} autoplayTimeout={2500}
                                             loop={true} nav={false} dots={true} margin={30}>
                                    {ServiceData.map((data, index) => (
                                        <ServiceCard img={data.img} heading={data.heading} para={data.para}
                                                     button={data.button} key={index}/>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'center', marginTop: '8%'}}>
                        <video src={img11} width="100%" height="400px" controls="controls" autoplay="true" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceHomeTwo
